import React from "react";
import Logo from "../assets/logo.png";
import { global } from "../constants";

const LogoText = () => {
  return (
    <div className="flex justify-start items-center cursor-pointer" onClick={() => window.location.replace("/#home")}>
      <img src={Logo} className="sm:w-[72px] w-[64px]" alt="Company Logo" />
      <div>
        <h1 className="text-lg ml-[16px] font-bold text-slate-100">{global.title}</h1>
      </div>
    </div>
  );
};

export default LogoText;
