import React from 'react'
import { aboutUsText } from '../constants'
import Directors from '../cosmetics/Directors'
import Logo from '../assets/logo.png'
import { global } from '../constants'

const About = () => {
  return (
    <section id="about" className="px-4">

      <div className="flex flex-col bg-white justify-center items-center pt-16 pb-16">
        <h1 className="md:text-4xl text-2xl font-bold text-gray-700">Despre noi</h1>
      </div>

      <div className="flex md:flex-row flex-col bg-[#FAF9F6] justify-between rounded-lg">
        <div className='sm:px-32 md:w-1/2 p-8'>
          <div className='p-4 border-2 border-gray-200 border-opacity-75 h-full'>
            <h1 className="md:text-2xl text-xl pb-8 text-center font-bold text-gray-700">{global.title}</h1>
            <p className="text-md text-gray-700">{aboutUsText[0]}</p>
            <br />
            <p className="text-md text-gray-700">{aboutUsText[1]}</p>
            <img src={Logo} alt="Logo-ul companiei" className="w-1/3 pt-5 mx-auto" />
          </div>
        </div>
        <Directors />
      </div>
    </section>
  )
}

export default About;