import React, { useState, useEffect } from "react";
import { navElements } from "../constants";
import { Link } from "react-scroll";
import NavMenu from "./NavMenu";

const Navbar = ({ ...rest }) => {
  const [hoveredIndex, setHoveredIndex] = useState(-1);
  const [toggle, setToggle] = useState(false);

  useEffect(() => {
    if (!toggle) {
      document.body.style.overflow = "auto";
    } else {
      document.body.style.overflow = "hidden";
    }
  }, [toggle]);

  return (
    <div {...rest}>
      <ul className="justify-end md:flex flex-1 items-center hidden">
        {navElements.map((element, index) => (
          <li
            key={element.id}
            className={`relative cursor-pointer mr-8 text-slate-100 hover:text-slate-300 ${index === navElements.length - 1 ? "mr-0" : "mr-8"
              }`}
            onMouseEnter={() => setHoveredIndex(index)}
            onMouseLeave={() => setHoveredIndex(-1)}
          >
            <Link to={element.id} smooth={true} duration={500}>
              {element.value}
              {hoveredIndex === index && (
                <div
                  className="absolute bottom-0 w-full h-[2px] bg-slate-300"
                  style={{
                    animation: "slide-in 0.6s forwards",
                    animationDelay: "0.4s",
                  }}
                />
              )}
            </Link>
          </li>
        ))}

        <li className="relative cursor-pointer mr-16">
          <Link to="contact" smooth={true} duration={800} className="inline-block rounded-full bg-slate-200 text-black px-6 py-2 border-2 border-slate-200 hover:bg-transparent hover:text-white hover:border-white transition-colors duration-300">
            Contactează-ne
          </Link>
        </li>
      </ul>

      <div
        className="md:hidden flex flex-1 justify-end items-end cursor-pointer object-contain"
        onClick={() => setToggle((prev) => !prev)}
      >
        <NavMenu toggle={toggle} />
      </div>
    </div>
  );
};

export default Navbar;

