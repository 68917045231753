import React from 'react';
import TeamCarousel from '../cosmetics/TeamCarousel';
import TeamAtWork1 from '../assets/team/teamwork-1.png';
import TeamAtWork2 from '../assets/team/teamwork-2.png';
import TeamAtWork3 from '../assets/team/teamwork-3.png';
import TeamAtWork4 from '../assets/team/teamwork-4.png';
import TeamAtWork5 from '../assets/team/teamwork-5.png';
import TeamAtWork6 from '../assets/team/teamwork-6.png';
import TeamPaintball from '../assets/team/team_paintball.jpeg';
import Quote from '../cosmetics/Quote';
import { quotes } from '../constants';

const Team = () => {
  const images = [TeamAtWork1, TeamAtWork2, TeamAtWork3, TeamAtWork4, TeamAtWork5, TeamAtWork6, TeamPaintball];

  return (
    <section id="team" className="px-4">

      <div className="flex flex-col bg-white justify-center items-center pt-16 pb-16">
        <h1 className="md:text-4xl text-2xl font-bold text-gray-700">Echipa</h1>
      </div>
      <div className="flex flex-col bg-[#FAF9F6] justify-center md:rounded-lg">
        <Quote
          content={quotes.team}
        />
        <div className='md:px-8 md:pb-16'>
          <TeamCarousel images={images} />
        </div>

      </div>
    </section>
  );
};

export default Team;
