export const global = {
  title: "Departament Eficiență Energetică SRL",
  subtitle: "Departament Eficiență Energetică",
  description: "Firmă de construcții - Energie cu eficiență",
};

export const aboutConstants = {
  title: "Despre noi:",
  description:
    "Grupul Departament Eficiența Energetică SRL Romania și Republica Moldova",
  mission:
    "Oferim clienților produse și servicii de înaltă tehnologie, soluții integrate dintr-o sursa care să cuprindă: ",
};

export const contactConstants = {
  email: "departament.eficienta.energetica@gmail.com",
  phone: "+40 774 477 444",
  address: "mun. Brasov, str. Iuliu Maniu, nr. 48, România",
};

export const navElements = [
  {
    id: "about",
    value: "Despre Noi",
  },
  {
    id: "projects",
    value: "Proiecte",
  },
  {
    id: "team",
    value: "Echipa",
  },
  {
    id: "services",
    value: "Servicii",
  }
];

export const miniServices = {
  concept: "Concepția",
  implementation: "Implementare",
  maintenance: "Întreținere",
  more: "Etc.",
};

export const services = [
  {
    id: "incalzire",
    imgSrc: "/assets/services/incalzire.png",
    title: "Soluții de Încălzire",
    childs: [
      { id: "centrale-termice", title: "Centrale termice" },
      { id: "instalatii-incalzire", title: "Instalații de încălzire" },
      { id: "incalzire-pardoseala", title: "Încălzire în pardoseală" },
      { id: "tuburi-radiante", title: "Tuburi radiante" },
      { id: "panouri-solare", title: "Panouri solare" },
      { id: "pompe-caldura", title: "Pompe de căldură" },
    ],
  },
  {
    id: "ventilatie",
    imgSrc: "/assets/services/ventilare.jpeg",
    title: "Instalări Ventilație",
    childs: [
      { id: "centrale-de-tratare-aer", title: "Centrale de tratare aer" },
      { id: "roof-top", title: "Roof-top" },
      { id: "generatoare-aer-cald", title: "Generatoare aer cald" },
      { id: "aeroterme", title: "Aeroterme" },
      { id: "tubulaturi", title: "Tubulaturi" },
      { id: "ventiloconvectoare", title: "Ventiloconvectoare" },
      { id: "ventilatoare", title: "Ventilatoare" },
    ],
  },
  {
    id: "climatizare",
    imgSrc: "/assets/services/climatizare.png",
    title: "Soluții de Climatizare",
    childs: [
      { id: "chillere", title: "Chillere" },
      { id: "turnuri-racire", title: "Turnuri de răcire" },
      { id: "aparate-aer-conditionat", title: "Aparate de aer condiționat" },
      { id: "pompe-caldura", title: "Pompe de căldură" },
    ],
  },
  {
    id: "solutii-inteligente",
    imgSrc: "/assets/services/inteligente.png",
    title: "Soluții Inteligente",
    childs: [
      { id: "automatizari", title: "Automatizări" },
      { id: "management-cladiri", title: "Managementul clădirilor" },
      { id: "management-energetic", title: "Managementul energetic" },
      { id: "eficientizare-energetica", title: "Eficientizarea energetică" },
      { id: "contorizare", title: "Contorizare" },
    ],
  },
  {
    id: "industrial",
    imgSrc: "/assets/services/industriale.png",
    title: "Servicii Industriale",
    childs: [
      { id: "instalatii-de-abur", title: "Instalații de abur" },
      { id: "aer-comprimat", title: "Aer comprimat" },
      { id: "fluide-tehnologice", title: "Fluide tehnologice" },
      { id: "tratare-apa", title: "Tratare apa" },
      { id: "automatizari-industriale", title: "Automatizări industriale" },
    ],
  },
  {
    id: "altele",
    title: "Și Altele ...",
    childs: [
      { id: "lucrari-de-constructii", title: "Lucrări de construcții" },
      { id: "instalatii-anti-incendiu", title: "Instalații anti-incendiu" },
      { id: "desfumare", title: "Desfumare" },
      { id: "aspiratie-centralizata", title: "Aspirație centralizată" },
      { id: "pereti-radianti", title: "Pereți radianți" },
    ],
  },
];

export const aboutUsText = [
  `${global.title} - Înființată în anul 2005, suntem o companie de construcții și eficiență energetică cu sediul în România și Republica Moldova. Ne-am dedicat să oferim clienților noștri soluții complete și inovatoare în domeniul construcțiilor, acoperind un spectru larg de servicii integrate dintr-o singură sursă.`,

  `Cu o echipă specializată și cu o vastă experiență în industrie, ne ocupăm de conceptul, implementarea și mentenanța instalațiilor de incalzire, climatizare, ventilatie, automatizare și de lucrările de construcție. Aducem în prim-plan confortul, economia de energie și sustenabilitatea în clădirile din România și Republica Moldova.`
];

export const projects = [
  {
    title: "MADV - MULTICONSULT ADVENTURE",
    url: "https://www.madv.ro/",
    imgSrc: "/assets/projects/proiectulvietii.png"
  },
  {
    title: "YZA MAR BLOKHIM SRL",
    url: "http://yzamar.ro/",
    imgSrc: "/assets/projects/yzamar.png"
  },
  {
    title: "CEZARA IDEAL CONSTRUCT SRL",
    imgSrc: "/assets/projects/cezara.png"
  },
  {
    title: "B.T.D. GROUP CONSTRUCTII SI AMBIENT SRL",
    imgSrc: "/assets/projects/btd.png"
  },
  {
    title: "BRENNEKA",
    url: "http://www.brenneka.ro/index.php",
    imgSrc: "/assets/projects/cladiri.png"
  },
  {
    title: "GMV GRUP SRL",
    imgSrc: "/assets/projects/gmv.png"
  },
  {
    title: "RENTINVEST SRL",
    url: "https://www.rentinvest.ro/index.html",
    imgSrc: "/assets/projects/rentinvest.png"
  }
]

export const quotes = {
  team: "Cu pasiunea noastră pentru construcții și eficiență energetică, suntem echipa de ingineri constructori dedicată care transformă visele în realitate. Punem laolaltă creativitatea noastră inovatoare și expertiza tehnică pentru a crea clădiri sustenabile, care aduc economii semnificative de energie și un impact pozitiv asupra mediului. Împreună, construim viitorul, punând bazele unei lumi mai verzi și mai durabile.",
  projects: `${global.title} a facut parte din următoarele experiențe cu adevărat deosebite construind un portfolio divers în industria construcțiilor și eficienței energetice. Suntem mândri să fim parteneri de încredere pentru clienții noștri, oferindu-le soluții complete și inovatoare în domeniul construcțiilor, acoperind un spectru larg de servicii integrate dintr-o singură și calificată sursă.`
}