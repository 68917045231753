import React, { useState } from 'react';
import { FaExternalLinkAlt } from 'react-icons/fa';
import { projects } from '../constants';
import './Projects.css';
import { CgMoreO } from "react-icons/cg";
import { Link } from "react-scroll";
import { MdKeyboardArrowDown } from "react-icons/md";
import Quote from '../cosmetics/Quote';
import { quotes } from '../constants';

const Projects = () => {
  const [hoveredIndex, setHoveredIndex] = useState(null);

  const handleMouseEnter = (index) => {
    setHoveredIndex(index);
  };

  const handleMouseLeave = () => {
    setHoveredIndex(null);
  };

  return (
    <section id="projects" className="px-4">
      <div className="flex flex-col bg-white justify-center items-center pt-16 pb-16">
        <h1 className="md:text-4xl text-2xl font-bold text-gray-700">
          Proiecte
        </h1>
      </div>

      <div className="flex flex-row py-4 bg-[#FAF9F6] items-center justify-between md:rounded-lg">
        {/* Image */}
        <div className="flex flex-col md:px-8 px-2">
          <Quote
            content={quotes.projects}
          />
          <div className="grid md:grid-cols-3 pt-4 grid-cols-1 md:gap-4 gap-y-6">
            {projects.map((project, index) => (
              <div
                key={index}
                className="text-gray-600 text-end w-full relative"
                onMouseEnter={() => handleMouseEnter(index)}
                onMouseLeave={handleMouseLeave}
              >
                <div className={`image-container ${hoveredIndex === index ? 'scaled' : ''}`}>
                  <img
                    className="object-cover md:rounded rounded-sm w-full h-full"
                    src={project.imgSrc}
                    alt={project.title}
                  />
                </div>
                {hoveredIndex === index && (
                  <div>
                    {project.url && (
                      <div className="absolute top-0 right-0 bg-opacity-[88%] rounded-lg bg-primary text-gray-800 text-center md:p-2 py-0.5 px-1">
                        <a
                          href={project.url}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="md:text-sm text-xs hover:cursor-pointer"
                        >
                          Vizitează site-ul{' '}
                          <FaExternalLinkAlt className="inline-block ml-2" />
                        </a>
                      </div>
                    )}
                    <div className="absolute bottom-0 md:text-base text-sm left-0 right-0 bg-opacity-[88%] rounded-lg bg-gray-200 text-gray-800 text-center md:py-2 py-1">
                      {project.title}
                    </div>
                  </div>
                )}
              </div>
            ))}
            <div className='border-4 border-gray-300 rounded'>
              <div className="object-cover md:rounded rounded-sm w-full h-full bg-gray-100 bg-opacity-80 md:flex hidden flex-col justify-center items-center transition-transform duration-300 transform hover:scale-105">
                <div className="p-1 relative">
                  <img
                    className="object-cover md:rounded rounded-sm w-full h-full filter blur-sm brightness-50 bg-black bg-opacity-50"
                    src="/assets/projects/others.png"
                    alt="Alte proiecte"
                  />
                </div>
                <div className="absolute inset-0 flex flex-col justify-center items-center">
                  <CgMoreO size={32} color="rgb(229, 231, 235)" />
                  <p className="text-gray-200 text-center text pt-4 font-bold">Și multe altele...</p>
                </div>
              </div>
            </div>


            <div
              className="flex border-4 border-gray-300 rounded flex-col justify-center items-center"
            >
              <p className='text-center italic p-4 text-gray-700'>Descoperiți gama vastă de servicii pe care le oferim clienților noștri și cum vă putem susține în fiecare etapă în industria construcțiilor!</p>
              <div className="py-4 inline-flex">
                <Link to="services" smooth={true} duration={800} className="underline text-lg text-gray-700 cursor-pointer">
                  Servicii
                </Link>
                <MdKeyboardArrowDown className="ml-2" size={24} color="#567B97" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Projects;
