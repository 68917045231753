import React from 'react';
import { services } from '../constants';
import { HiCheck } from 'react-icons/hi';

const ServicesDashboard = () => {
  return (
    <>
      {services.map((item, index) => (
        <div
          key={item.id}
          className={`flex flex-row p-4 md:${index === 0 ? 'py-8' : 'py-16'} md:justify-end justify-center items-center ${index % 2 === 0 ? '' : 'flex-row-reverse'
            }`}
        >
          <div className="flex md:px-24 px-6 flex-col bg-gradient-to-br from-[#7B9981] to-[#6e8d70] opacity-80 rounded-lg shadow-lg flex-1">
            <h1 className="md:text-2xl text-xl p-4 font-bold text-gray-200">{item.title}</h1>
            <div className="border-b-2 border-gray-200 w-full"></div>
            <ul className="flex flex-col justify-between h-full"> {/* Use flex-col and h-full */}
              {item.childs.map((child) => (
                <li
                  key={child.id}
                  className="flex flex-row md:text-base text-sm md:m-4 m-2 items-center justify-start hover:text-gray-100 text-gray-300 h-full"
                >
                  <HiCheck color="#0E8305" />
                  <p className="ml-4 hover:transform hover:translate-x-2 hover:translate-y-[-2px] transition duration-300">{child.title}</p>
                </li>
              ))}
            </ul>
          </div>
          {item.imgSrc &&
            <div className={`p-2 border-4 border-opacity-60 md:block hidden rounded-sm border-gray-300   ${index % 2 === 0 ? "translate-x-[-20px]" : "translate-x-[20px]"}`}>
              <img src={item.imgSrc} alt={`Servicii ${item.title}`} className={`h-[350px] shadow-lg rounded-lg`} />
            </div>
          }
        </div>
      ))}
    </>
  );
};

export default ServicesDashboard;
