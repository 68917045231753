import React, { useEffect, useState } from 'react';
import { useSwipeable } from 'react-swipeable';

const TeamCarousel = ({ images }) => {
  const [currentSlide, setCurrentSlide] = useState(0);

  const goToSlide = (slideIndex) => {
    setCurrentSlide(slideIndex);
  };

  const handleSwipe = useSwipeable({
    onSwipedLeft: () => {
      const nextSlide = (currentSlide + 1) % images.length;
      setCurrentSlide(nextSlide);
    },
    onSwipedRight: () => {
      const prevSlide = (currentSlide - 1 + images.length) % images.length;
      setCurrentSlide(prevSlide);
    },
    preventDefaultTouchmoveEvent: true,
    trackMouse: true,
  });

  useEffect(() => {
    const interval = setInterval(() => {
      const nextSlide = (currentSlide + 1) % images.length;
      setCurrentSlide(nextSlide);
    }, 5000);
    return () => clearInterval(interval);
  }, [currentSlide, images.length]);

  return (
    <div className="relative" {...handleSwipe}>
      <div className="flex flex-col justify-center items-center">
        <div className="overflow-hidden md:w-[80%] w-[95%]">
          <div
            className="flex transition-transform ease-in-out duration-300"
            style={{
              transform: `translateX(-${currentSlide * 100}%)`,
            }}
          >
            {images.map((image, index) => (
              <div key={index} className="w-full md:h-[512px] h-[256px] flex-shrink-0">
                <img className="object-cover w-full h-full md:rounded-lg rounded-sm" src={image} alt={`Team Member ${index + 1}`} />
              </div>
            ))}
          </div>
        </div>
        <div className="justify-center items-center p-2">
          <div className="bg-green-200 p-1 rounded-full">
            <div className="flex space-x-2">
              {images.map((_, index) => (
                <button
                  key={index}
                  className={`w-3 h-3 rounded-full ${index === currentSlide ? 'bg-green-500' : 'bg-gray-400'
                    }`}
                  onClick={() => goToSlide(index)}
                ></button>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TeamCarousel;