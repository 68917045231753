import React from 'react'
import ServicesDashboard from '../cosmetics/ServicesDashboard'
import { aboutConstants } from '../constants'

import { RiPencilRuler2Line } from "react-icons/ri";
import { BiWrench } from "react-icons/bi";
import { AiOutlineReconciliation } from "react-icons/ai";
import { miniServices } from "../constants";
import { CgMoreO } from "react-icons/cg";

const Services = () => {
  return (
    <section id="services" className="px-4">

      <div className="flex flex-col bg-white justify-center items-center pt-16 pb-16">
        <h1 className="md:text-4xl text-2xl font-bold text-gray-700">Servicii</h1>
      </div>

      <div className="flex flex-col bg-[#FAF9F6] items-center justify-between md:rounded-lg">
        <p className="pt-16 pb-4 text-center font-semibold  md:text-lg text-sm text-gray-600">{aboutConstants.mission}</p>
        <div className='flex flex-row'>
          <ul className="px-6 md:flex hidden flex-row justify-between pb-4 items-center">
            <li className="text-gray-600 text-lg flex flex-row justify-start pr-6 items-center hover:transform hover:translate-x-2 hover:translate-y-[-2px] transition duration-300">
              <AiOutlineReconciliation size={28} color="#567B97" />
              <p className="pl-2">{miniServices.concept}</p>
            </li>

            <li className="text-gray-600 text-lg flex flex-row justify-start px-6 items-center hover:transform hover:translate-x-2 hover:translate-y-[-2px] transition duration-300">
              <RiPencilRuler2Line size={28} color="#567B97" />
              <p className="pl-2">{miniServices.implementation}</p>
            </li>

            <li className="text-gray-600 text-lg flex flex-row justify-start px-6 items-center hover:transform hover:translate-x-2 hover:translate-y-[-2px] transition duration-300">
              <BiWrench size={28} color="#567B97" />
              <p className="pl-2">{miniServices.maintenance}</p>
            </li>

            <li className="text-gray-600 text-lg flex flex-row justify-start pl-6 items-center hover:transform hover:translate-x-2 hover:translate-y-[-2px] transition duration-300">
              <CgMoreO size={28} color="#567B97" />
              <p className="pl-2">{miniServices.more}</p>
            </li>
          </ul>
        </div>
        <div className="border-b-2 border-gray-200 pt-2 w-full"></div>
        <div className='md:w-[65%] pt-6 w-[80%]'>
          <ServicesDashboard />
        </div>
      </div>
    </section>
  )
}

export default Services