import React from 'react'
import Director1 from '../assets/director-1.png'
import Director2 from '../assets/director-2.png'
import { global } from '../constants'

const Directors = () => {
  return (
    <div className="items-center md:w-1/2 p-8">
      <div className='p-4'>
        <div className="flex flex-col w-full">
          <h1 className="md:text-2xl text-xl pb-8 text-center font-bold text-gray-700">Directori Generali ai {global.title.toUpperCase()}:</h1>
          <div className="flex flex-row pb-8 items-center justify-start">
            <img src={Director1} alt="Director 1" className="sm:w-1/3 w-1/2 mr-4" />
            <div>
              <h3 className="md:text-lg font-medium">BÎRSA MIHAIL</h3>
              <p className="md:text-base text-sm text-gray-600">Director General {global.title}</p>
            </div>
          </div>
          <div className="flex flex-row items-center justify-end">
            <div>
              <h3 className="md:text-lg text-right font-medium">GHENADIE MITROFANOV</h3>
              <p className="md:text-base text-sm text-gray-600 text-right">Director de Dezvoltare {global.title}</p>
            </div>
            <img src={Director2} alt="Director 2" className="sm:w-1/3 w-1/2 ml-4" />
          </div>
        </div>
      </div>
    </div>
  )
}

export default Directors
