import React from 'react'
import { MdPhonelinkRing } from 'react-icons/md'
import { FiMail } from 'react-icons/fi'
import { BiMap } from 'react-icons/bi'
import { FaFacebook } from "react-icons/fa"

const Contact = () => {
  return (
    <section className="px-4">
      <div className="flex flex-col bg-white justify-center items-center pt-16 pb-16">
        <br />
      </div>

      <footer className="flex flex-col bg-[#FAF9F6] items-center md:rounded-lg">
        <div className="border-b-8 border-gray-200 my-8 w-full" />

        <div id="contact" className="flex flex-col justify-center items-center md:pb-8 pb-4">
          <h1 className="md:text-4xl text-2xl font-bold underline text-gray-700">Cum ne găsești:</h1>
        </div>

        <div className='md:flex hidden flex-row justify-between items-start md:w-[80%] w-full'>
          <div className='flex flex-col items-start w-1/2 px-8'>
            <h2 className=' text-xl'>Contacte:</h2>
            <div className='border-[1px] border-gray-700 w-full mb-4' />
            <div className='flex flex-row pb-5 items-center'>
              <MdPhonelinkRing size={26} color="text-gray-600" />
              <a className="md:text-base text-sm pl-10" href="tel:+40774477444">:  <span className='underline hover:cursor-pointer'>+40 774 477 444</span></a>
            </div>
            <div className='flex flex-row pb-5 items-center'>
              <FiMail size={26} color="text-gray-600" />
              <a className='md:text-base text-sm pl-10' href="mailto:departament.eficienta.energetica@gmail.com">:  <span className='underline hover:cursor-pointer'> departament.eficienta.energetica@gmail.com</span></a>
            </div>
            <div className='flex flex-row pb-5 items-center'>
              <FaFacebook size={26} color="#1877F2" />
              <a className='md:text-base text-sm pl-10' target="_blank" rel="noopener noreferrer" href="https://www.facebook.com/profile.php?id=100076051899104">:  <span className='underline hover:cursor-pointer'> facebook.com/hr </span></a>
            </div>
          </div>
          <div className="w-1/2 px-8">
            <h2 className='text-lg'>Adresa:</h2>
            <div className='border-[1px] border-gray-700 w-full mb-4' />
            <div className='flex flex-row items-center'>
              <BiMap size={26} color="text-gray-600" />
              <a href="https://goo.gl/maps/fn9peTngCR9xpRfq5" target="_blank" rel="noopener noreferrer" className="md:text-base text-sm pl-10">:  <span className='underline hover:cursor-pointer'>mun. Brasov, str. Iuliu Maniu, nr. 48</span></a>
            </div>
          </div>
        </div>
        <div className='flex md:hidden flex-col items-start'>
          <h2 className='text-lg font-bold'>Contacte:</h2>
          <div className='flex flex-row py-5 items-center'>
            <MdPhonelinkRing size={16} />
            <a className="text-sm pl-2" href="tel:+40774477444"><span className='underline hover:cursor-pointer'>+40 774 477 444</span></a>
          </div>
          <div className='flex flex-row pb-5 items-center'>
            <FiMail size={16} color="text-gray-600" />
            <a className='text-sm pl-2' href="mailto:departament.eficienta.energetica@gmail.com"><span className='underline hover:cursor-pointer'>departament.eficienta.energetica@gmail.com</span></a>
          </div>
          <div className='flex flex-row pb-5 items-center'>
            <FaFacebook size={16} color="#1877F2" />
            <a className="text-sm pl-2" target="_blank" rel="noopener noreferrer" href="https://www.facebook.com/profile.php?id=100076051899104"><span className='underline hover:cursor-pointer'> facebook.com/hr </span></a>
          </div>
          <h2 className='text-lg font-bold'>Adresa:</h2>
          <div className='flex flex-row py-5 items-center'>
            <BiMap size={16} color="text-gray-600" />
            <a href="https://goo.gl/maps/fn9peTngCR9xpRfq5" target="_blank" rel="noopener noreferrer" className="text-sm pl-2"><span className='underline hover:cursor-pointer'>mun. Brasov, str. Iuliu Maniu, nr. 48</span></a>
          </div>
        </div>
        <div className="border-b-8 border-gray-200 my-8 w-full" />
      </footer>
    </section>
  )
}

export default Contact