import React from "react";
import { global } from "../constants";
import CompanyImage from "../assets/company.jpeg";
import Card from "../cosmetics/Card";
import { GoInfo } from "react-icons/go";
import { HiOutlineWrenchScrewdriver, HiOutlineUserGroup } from "react-icons/hi2";
import { Link } from "react-scroll";

import Header from "./Header";
import { MdPhonelinkRing } from "react-icons/md";

const Hero = ({ children }) => {
  const { title } = global;

  const gradientOverlayStyle = {
    backgroundImage: `linear-gradient(to bottom, rgba(25, 25, 25, 0.8), rgba(25, 25, 25, 0.7))`,
  };

  const imageStyle = {
    objectFit: "cover",
    filter: "blur(1px)", // Add a bit of a blur to the image
  };

  return (
    <section id="home">
      <div className="relative shadow-lg" style={{ height: "100vh" }}>
        <div className="w-full relative overflow-hidden shadow-lg flex items-center h-full">
          <img
            className="object-cover h-full w-full"
            src={CompanyImage}
            alt={title}
            style={imageStyle}
          />
          <div
            className="absolute top-0 left-0 w-full h-full"
            style={gradientOverlayStyle}
          />
          <div className="absolute top-0 left-0 w-full h-full flex flex-col justify-between items-center">
            <Header />

            <div className="sm:px-16 w-[70%] flex flex-col justify-start items-start">
              <h1 className="md:text-5xl text-2xl text-slate-200 py-3 font-bold">{title}</h1>
              <p className="text-slate-200 md:text-2xl text-sm">
                Construim și oferim soluții de eficiență energetică în România și Republica
                Moldova, aducând inovație și tehnologie de vârf în domeniul construcțiilor.
              </p>
            </div>
            <div className="md:hidden hover:cursor-pointer base inline-block rounded-full bg-slate-200 text-black px-6 py-2 border-2 border-slate-200 hover:bg-transparent hover:text-white hover:border-white transition-colors duration-300">
              <div className="flex flex-row items-center">
                <Link to="contact" smooth={true} duration={800} className="">
                  Contactează-ne
                </Link>
                <MdPhonelinkRing size={24} color="gray" className="ml-4" />
              </div>
            </div>
            <div className="w-[80%] flex md:flex-row flex-col justify-between items-center">
              <Card
                key="about"
                section="about"
                title="Despre Noi"
                description={`Directorii generali și detalii despre ${title}!`}
                icon={<GoInfo />}
              />
              <Card
                key="team"
                section="team"
                title="Echipa"
                description="Cunoașteți echipa noastră de specialiști ingineri!"
                icon={<HiOutlineUserGroup />}
              />
              <Card
                key="services"
                section="services"
                title="Servicii"
                description="Descoperă serviciile noastre și cu ce vă putem fi de cel mai mare folos!"
                icon={<HiOutlineWrenchScrewdriver />}
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Hero;
