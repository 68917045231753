import React, { useState, useEffect } from 'react';
import NavMenu from './NavMenu';

const FloatingActionButton = ({showVisible, onClick}) => {
  const [toggle, setToggle] = useState(false);
  const [visible, setVisible] = useState(false);

  const handleClick = () => {
    setToggle((toggle) => !toggle);
  }

  useEffect(() => {
    if (!toggle) {
      document.body.style.overflow = "auto";
    } else {
      document.body.style.overflow = "hidden";
    }
  }, [toggle]);

  useEffect(() => {
    const showButton = () => {
      setVisible(showVisible);
    };

    // Delay the appearance of the button to allow for the animation
    const timeout = setTimeout(showButton, 10);

    return () => clearTimeout(timeout);
  }, [showVisible]);

  return (
    <div className={`fixed bottom-4 right-4 transition-opacity duration-300 ${visible ? 'opacity-100' : 'opacity-0'} z-10`}>
      <div
        className={`flex justify-center items-center w-16 h-16 bg-[#00715F] rounded-full shadow-xl  ${visible && "cursor-pointer"}`}
        onClick={handleClick}
      >
        <NavMenu toggle={toggle} />
      </div>
    </div>
  );
};



export default FloatingActionButton;
