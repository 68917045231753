import React from "react";
import { HiOutlineListBullet } from "react-icons/hi2";
import { scroller } from "react-scroll";

const Card = ({ title, description, icon, section }) => {

  const scrollTo = (offset) => {
    scroller.scrollTo(section, {
      duration: 500,
      delay: 0,
      smooth: "easeInOutQuart",
      offset: offset
    });
  }

  return (
    <div className="md:w-1/3 w-full md:p-6 py-2 hover:cursor-pointer" onClick={() => scrollTo()}>
      <div className="hover:bg-none border-white border-2 rounded-lg overflow-hidden shadow-xl hover:shadow-3xl transform hover:-translate-y-1 hover:scale-100 transition duration-500">
        {/* <img className="w-full h-64 object-cover hidden md:block" src={image} alt={title} /> */}
        <div className="flex flex-col justify-start items-start md:justify-start md:items-start p-4">
          <div className="flex flex-row w-full justify-between items-center md:pb-4">
            <div className="flex flex-row items-center">
              {React.cloneElement(icon, { size: 26, color: "white" })}
              <h2 className="md:text-md text-sm text-slate-200 pl-4 font-bold ">{title}</h2>
            </div>
            <div className="pl-24">
              <HiOutlineListBullet size={26} color="white" />
            </div>
          </div>
          <p className="text-slate-200 text-sm hidden md:block">{description}</p>
        </div>
      </div>
    </div>
  );
};

export default Card;
