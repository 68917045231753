import React from 'react'
import { FaQuoteLeft, FaQuoteRight } from 'react-icons/fa';

const Quote = ({ content }) => {
    return (
        <div className="flex flex-col items-center pt-4 justify-center">
            <div className="border-b-2 border-gray-300 md:w-1/2 w-[80%] my-4"></div>

            <div className="flex flex-row justify-start pr-[40%]">
                <FaQuoteLeft color="#4B5563" size={14} className="" />
            </div>
            <p className="text-gray-600 md:text-base text-xs italic p-3 text-center md:w-1/2 w-[80%]">
                {content}
            </p>
            <div className="flex items-end justify-end pl-[40%]">
                <div className="flex-none">
                    <FaQuoteRight color="#4B5563" size={14} className="" />
                </div>
            </div>
            <div className="border-b-2 border-gray-300 md:w-1/2 w-[80%] my-4"></div>
        </div>
    )
}

export default Quote