import React, { useState, useEffect } from "react";
import Hero from "./components/Hero";
import About from "./components/About";
import FloatingActionButton from "./cosmetics/FloatingActionButton";
import Team from "./components/Team";
import Services from "./components/Services"
import Contact from "./components/Contact";
import Projects from "./components/Projects";

const App = () => {
  const [showFloatingButton, setShowFloatingButton] = useState(false);
  const [floatingButtonVisible, setFloatingButtonVisible] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const heroSectionHeight = document.getElementById("home").offsetHeight;
      const scrollPosition =
        window.scrollY || document.documentElement.scrollTop;

      if (scrollPosition > heroSectionHeight / 3) {
        setShowFloatingButton(true);
      } else {
        setShowFloatingButton(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    if (showFloatingButton) {
      const timeout = setTimeout(() => {
        setFloatingButtonVisible(true);
      }, 10);

      return () => clearTimeout(timeout);
    } else {
      setFloatingButtonVisible(false);
    }
  }, [showFloatingButton]);

  return (
    <>
      <Hero />
      <About />
      <Projects />
      {<FloatingActionButton showVisible={floatingButtonVisible} />}
      <Team />
      <Services />
      <Contact />
    </>
  );
};

export default App;