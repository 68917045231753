import React from "react";
import LogoText from "../cosmetics/LogoText";
import Navbar from "../cosmetics/Navbar";
import { contactConstants } from "../constants";
import { MdPhonelinkRing } from "react-icons/md";

const Header = () => {
  return (
    <header className="w-full pb-16 z-10">
      <div className="flex flex-col">
        <div className="flex md:justify-between justify-between rounded-sm items-center m-6">
          <div className="md:ml-32 py-2 opacity-100">
            <LogoText />
          </div>
          <Navbar className="md:mr-32 md:py-2" />
        </div>
        <div className="flex flex-row justify-end items-center">
          <MdPhonelinkRing className="text-right hidden md:block text-sm text-slate-200 mr-2 translate-y-[-30px] " size={22} color="#E2E8F0" />
          <a href="tel:+40774477444" className="text-right hidden md:block text-base text-slate-200 translate-y-[-30px] mr-[222px]">{contactConstants.phone}</a>
        </div>
        <div className="border-b-2 md:translate-y-[-30px] translate-y-[-20px] border-slate-200 md:mx-36 mx-4" />
      </div>
    </header>
  );
};

export default Header;
