import React from 'react'
import { IoIosArrowForward } from "react-icons/io";
import { RxHamburgerMenu } from "react-icons/rx";
import { navElements } from "../constants";

const NavMenu = ({ toggle }) => {

  return (
    <>
      <div className="z-20">
        {!toggle ? (
          <RxHamburgerMenu size={32} color="white" />
        ) : (
          <IoIosArrowForward size={32} color="white" />
        )}
      </div>

      <div
        className={`${toggle ? "flex" : "hidden"
          } p-6 fixed inset-0 bg-gray-600 bg-opacity-[95%] z-10`}
      >
        <ul className="list-none flex flex-col justify-center items-end flex-1 z-11">
          {navElements.map((nav, index) => (
            <li
              key={nav.id}
              className={`cursor-pointer text-[20px] text-slate-200 z-11 border-b-2 mb-4 border-slate-200`}
            >
              <a className="z-15" href={`#${nav.id}`}>
                {nav.value}
              </a>
            </li>
          ))}
          <li className="cursor-pointer text-[20px] text-slate-200 z-11 border-b-2 border-slate-200">
            <a className="z-15" href={`#contact`}>
              Contactează-ne
            </a>
          </li>
        </ul>
      </div>
    </>
  )
}

export default NavMenu